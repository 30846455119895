import React from 'react';
import {
  Badge,
  Box,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Photo from '@mui/icons-material/Photo';
import { OrganizationLink } from '../../organizations/organization-link';
import { StorageBucket } from '../../../helpers/supabase';
import { DealType } from '../../enums/deal-type.enum';
import { neutral } from '../../theme/colors';
import { DealStrategy } from '../../enums/deal-strategy.enum';
import { DealSector } from '../../enums/deal-sector.enum';
import { oxfordComma } from '../../../helpers/strings';
import { DealBanner } from '../deal-banner';
import { GppGood } from '@mui/icons-material';

const IMG_HEIGHT = 245;

export const SearchResultPublic = ({ deal, preview, onClick, setSnackbar }) => {
  const isFund = deal.type === DealType.Fund;
  const galleryImages = deal.images?.length
    ? deal.images?.map((img) => {
        const imageSrc = `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.OFFERING_IMAGES}/${deal.id}/${img.name}`;
        return {
          original: imageSrc,
          thumbnail: imageSrc,
        };
      })
    : null;
  const hasImg = galleryImages?.length;

  const sectors = deal.sectors?.split(',') ?? [];

  const uniqueProjects =
    deal.assets?.length > 0
      ? 'Projects in ' +
        oxfordComma(
          deal.assets.reduce((accumulator, asset) => {
            if (!accumulator.includes(asset.state)) {
              accumulator.push(asset.state);
            }
            return accumulator;
          }, []),
        )
      : 'Projects TBD';

  return (
    <>
      <DealBanner deal={deal} setSnackbar={setSnackbar} isSearch />
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: IMG_HEIGHT,
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        {hasImg ? (
          <>
            <img
              src={`${galleryImages[0].original}?fit=crop&auto=format`}
              alt={deal.name}
              style={{ width: '100%', height: '100%' }}
            />
            {deal.diligenceBy && (
              <Tooltip
                title={`Diligence report available from ${deal.diligenceBy}`}
              >
                <Badge
                  badgeContent={<GppGood color="warning" />}
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{ position: 'absolute', top: 24, left: 24 }}
                />
              </Tooltip>
            )}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: neutral[200],
              borderRadius: 5,
            }}
          >
            <Photo style={{ width: '100%', height: '100%' }} />
          </div>
        )}
      </Box>
      <Box
        onClick={onClick}
        sx={{
          cursor: preview ? 'default' : 'pointer',
          flex: 1,
        }}
      >
        <Stack sx={{ mt: 2, px: 4 }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack>
              <Typography variant={'h5'}>
                {deal.city}, {deal.state}
              </Typography>
              <Typography variant={'body1'}>{deal.name}</Typography>
              <OrganizationLink
                deal={deal}
                organization={deal.organization}
                isPublic
              />
            </Stack>
          </Stack>
          <Divider sx={{ my: 1 }} />
          {isFund ? (
            <Typography variant={'caption'}>{uniqueProjects}</Typography>
          ) : (
            <Typography variant={'caption'}>
              {DealStrategy[deal.strategy]}
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              mt: 1,
              overflowX: 'scroll',
            }}
          >
            {sectors.map((value) => (
              <Chip
                sx={{ my: 0.33, mr: 0.33 }}
                key={value}
                label={DealSector[value]}
                variant="contained"
                size="small"
              />
            ))}
          </Box>
        </Stack>
      </Box>
    </>
  );
};
