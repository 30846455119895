import { Alert, Stack, Typography } from '@mui/material';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useApproveOrganization } from '../hooks/organizations/use-organizations';
import { UsersTable } from './users-table';
import { useApproveUser } from '../hooks/users/use-users';

export default function UserApprovals({ users, isLoading, setSnackbar }) {
  const approveUser = useApproveUser();

  const handleApprove = async (user) => {
    try {
      approveUser.mutate({
        id: user.id,
      });
      setSnackbar({ message: user.name + ' has been approved!' });
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
    }
  };

  return isLoading ? (
    <Stack alignItems="center">
      <CircularProgress />
    </Stack>
  ) : (
    <>
      {users?.length > 0 ? (
        <UsersTable items={users} onApproveClick={handleApprove} />
      ) : (
        <Alert severity="success" sx={{ width: '100%' }}>
          {`You're all caught up! There are no Investors to approve at this time. 😎`}
        </Alert>
      )}
    </>
  );
}
