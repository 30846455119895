import { useApi } from '../api/use-api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RetryConfig } from '../api/retry-config';
import { handleMutate } from '../util/use-handle-mutate';

export const UserQueryKeys = {
  all: ['users'], //for manage deals
  approvals: () => [...UserQueryKeys.all, 'approvals'],
  rejected: () => [...UserQueryKeys.all, 'rejected'],
  detail: (id) => [...UserQueryKeys.all, id], //for a single deal detail
};

const queryKey = 'user';

const useUser = ({ id, options }) => {
  const request = useApi();
  return useQuery(
    UserQueryKeys.detail(id),
    async () => request({ url: `/users/${id}` }),
    {
      ...RetryConfig(),
      ...options,
    },
  );
};

const useApprovalUsers = () => {
  const request = useApi();
  return useQuery(
    UserQueryKeys.approvals(),
    async () => request({ url: `/users/approvals` }),
    {
      ...RetryConfig(),
    },
  );
};

const useRejectedUsers = () => {
  const request = useApi();
  return useQuery(
    UserQueryKeys.rejected(),
    async () => request({ url: `/users/rejected` }),
    {
      ...RetryConfig(),
    },
  );
};

const useUpdateUser = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) =>
      request({ url: `/users/${id}`, method: 'PATCH', body: updated }),
    {
      onMutate: async ({ id, updated }) => {
        await queryClient.cancelQueries([queryKey]);
        const previousValue = queryClient.getQueryData([queryKey]);
        if (previousValue) {
          queryClient.setQueryData([queryKey], (old) => ({
            ...old,
            ...request,
          }));
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([queryKey]).then();
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateUserDealFilters = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) =>
      request({
        url: `/users/${id}/profile/filters`,
        method: 'POST',
        body: updated,
      }),
    {
      onMutate: async ({ id, updated }) => {
        await queryClient.cancelQueries([queryKey]);
        const previousValue = queryClient.getQueryData([queryKey]);
        if (previousValue) {
          queryClient.setQueryData([queryKey], (old) => ({
            ...old,
            sectors: updated.sectors,
            states: updated.states,
          }));
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([queryKey]).then();
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateUserNotificationChannels = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) =>
      request({
        url: `/users/${id}/profile/notifications`,
        method: 'POST',
        body: updated,
      }),
    {
      onMutate: async ({ id, updated }) => {
        await queryClient.cancelQueries([queryKey]);
        const previousValue = queryClient.getQueryData([queryKey]);
        if (previousValue) {
          queryClient.setQueryData([queryKey], (old) => ({
            ...old,
            channels: updated.channels,
          }));
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([queryKey]).then();
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateUserQualification = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) =>
      request({
        url: `/users/${id}/profile/qualification`,
        method: 'POST',
        body: updated,
      }),
    {
      onMutate: async ({ id, updated }) => {
        await queryClient.cancelQueries([queryKey]);
        const previousValue = queryClient.getQueryData([queryKey]);
        if (previousValue) {
          queryClient.setQueryData([queryKey], (old) => ({
            ...old,
            qualification: { ...updated },
          }));
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([queryKey]).then();
      },
      ...RetryConfig(),
    },
  );
};

const useApproveUser = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }) =>
      request({
        url: `/users/${id}/approve`,
        method: 'POST',
      }),
    {
      onMutate: async ({ id }) => {
        const prevDetail = await handleMutate(
          queryClient,
          UserQueryKeys.detail(id),
          (old) => {
            old.approvedAt = new Date().toISOString();
            return old;
          },
        );

        const prevApprovals = await handleMutate(
          queryClient,
          UserQueryKeys.approvals(),
          (old) => old.filter((u) => u.id !== id),
        );

        const prevRejected = await handleMutate(
          queryClient,
          UserQueryKeys.rejected(),
          (old) => old.filter((u) => u.id !== id),
        );

        return {
          prevApprovals,
          prevRejected,
          prevDetail,
        };
      },
      // On failure, roll back to the previous value
      onError: (
        err,
        variables,
        { prevApprovals, prevRejected, prevDetail },
      ) => {
        queryClient.setQueryData(
          UserQueryKeys.detail(variables.id),
          prevDetail,
        );
        queryClient.setQueryData(UserQueryKeys.approvals(), prevApprovals);
        queryClient.setQueryData(UserQueryKeys.rejected(), prevRejected);
      },
      onSuccess: (data, error, variables) => {
        queryClient.invalidateQueries(UserQueryKeys.approvals()).then();
        queryClient.invalidateQueries(UserQueryKeys.rejected()).then();
      },
      ...RetryConfig(),
    },
  );
};

const useRejectUser = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (user) =>
      request({
        url: `/users/${user.id}/reject`,
        method: 'POST',
      }),
    {
      onMutate: async (user) => {
        const prevDetail = await handleMutate(
          queryClient,
          UserQueryKeys.detail(user.id),
          (old) => {
            old.rejectedAt = new Date().toISOString();
            old.approvedAt = null;
            return old;
          },
        );

        const prevApprovals = await handleMutate(
          queryClient,
          UserQueryKeys.approvals(),
          (old) => old.filter((u) => u.id !== user.id),
        );

        const prevRejected = await handleMutate(
          queryClient,
          UserQueryKeys.rejected(),
          (old) => [...old, user],
        );

        return {
          prevApprovals,
          prevRejected,
          prevDetail,
        };
      },
      // On failure, roll back to the previous value
      onError: (
        err,
        variables,
        { prevApprovals, prevRejected, prevDetail },
      ) => {
        queryClient.setQueryData(
          UserQueryKeys.detail(variables.user.id),
          prevDetail,
        );
        queryClient.setQueryData(UserQueryKeys.approvals(), prevApprovals);
        queryClient.setQueryData(UserQueryKeys.rejected(), prevRejected);
      },
      onSuccess: (data, error, variables) => {
        queryClient.invalidateQueries(UserQueryKeys.approvals()).then();
        queryClient.invalidateQueries(UserQueryKeys.rejected()).then();
      },
      ...RetryConfig(),
    },
  );
};

export {
  useUser,
  useUpdateUser,
  useUpdateUserDealFilters,
  useUpdateUserNotificationChannels,
  useUpdateUserQualification,
  useApprovalUsers,
  useApproveUser,
  useRejectedUsers,
  useRejectUser,
};
