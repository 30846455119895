import {
  differenceInCalendarDays,
  differenceInHours,
  formatDistanceStrict,
} from 'date-fns';
import { Badge, Chip, Grid, Stack, Typography } from '@mui/material';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import AddAlarm from '@mui/icons-material/AddAlarm';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationChannel } from '../enums/notification-channel.enum';
import { localTime } from '../../helpers/dates';
import { AttachMoney } from '@mui/icons-material';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import { info } from '../theme/colors';
import Box from '@mui/material/Box';

export const NotificationRow = ({ note }) => {
  const navigate = useNavigate();
  const now = new Date();
  const sentAtLocal = localTime(note.createdAt);
  const daysSinceSent = differenceInCalendarDays(now, sentAtLocal);
  const hoursSinceSent = differenceInHours(now, sentAtLocal);
  const formatSentAt = formatDistanceStrict(sentAtLocal, now, {
    unit: hoursSinceSent <= 1 ? 'minute' : daysSinceSent <= 1 ? 'hour' : 'day',
  });

  const metadata = JSON.parse(note.metadata);
  const { message, deal, thread } = metadata;

  const handleMessageClick = (e) => {
    e.stopPropagation();
    navigate('/messages?tid=' + thread?.externalId);
  };

  const handleDealClick = (e) => {
    e.stopPropagation();
    navigate('/deals/' + deal?.id);
  };

  const minWidth = 175;

  const renderContent = () => {
    if (note.channel === NotificationChannel.DealApproval) {
      const { deal } = metadata;

      return (
        <>
          <Typography variant="body1">
            <Typography variant="link" onClick={handleDealClick}>
              {deal.name}
            </Typography>{' '}
            is active and accepting investments
          </Typography>
        </>
      );
    }

    if (note.channel === NotificationChannel.DealFundingAdded) {
      const { deal, threshold } = metadata;

      return (
        <>
          <Typography variant="body1">
            <Typography variant="link" onClick={handleDealClick}>
              {deal.name}
            </Typography>{' '}
            is now <b>{threshold}</b> funded
          </Typography>
        </>
      );
    }

    if (note.channel === NotificationChannel.DealMessage) {
      const { user, deal } = metadata;

      return (
        <>
          <Typography variant="body1">
            <b>{user.name}</b> sent you a message about{' '}
            <Typography variant="link" onClick={handleDealClick}>
              {deal.name}
            </Typography>
          </Typography>
        </>
      );
    }

    if (note.channel === NotificationChannel.DealInvestmentFollowUp) {
      const { deal } = metadata;

      return (
        <>
          <Typography variant="body1" color={'text.secondary'}>
            Did you decide to invest in
          </Typography>
          <Link to={'/deals/' + deal.id + '?openDialog=Invest'}>
            {deal.name}
          </Link>
        </>
      );
    }
  };

  const renderChip = () => {
    if (note.channel === NotificationChannel.DealApproval) {
      return (
        <Chip label={'New Deal Added'} icon={<AddAlarm />} sx={{ minWidth }} />
      );
    }

    if (note.channel === NotificationChannel.DealFundingAdded) {
      return (
        <Chip
          label={'Funding Added'}
          icon={<AttachMoney />}
          sx={{ minWidth }}
        />
      );
    }

    if (note.channel === NotificationChannel.DealMessage) {
      return (
        <Chip
          label={'Message Received'}
          icon={<EmailOutlined />}
          sx={{ minWidth }}
        />
      );
    }

    if (note.channel === NotificationChannel.DealInvestmentFollowUp) {
      return (
        <Chip
          label={`Deal Follow-Up'}`}
          icon={<CurrencyExchange />}
          sx={{ minWidth }}
        />
      );
    }
  };

  const isViewed = note.views?.length > 0;
  const isMessage = note.channel === NotificationChannel.DealMessage;

  return (
    <>
      <Grid
        item
        xs={5}
        sm={4}
        md={3}
        lg={2}
        sx={{
          py: 4,
          backgroundColor: isViewed ? '' : info.lightest,
          borderBottom: '1px solid lightgrey',
        }}
      >
        {renderChip()}
      </Grid>
      <Grid
        item
        xs={7}
        sm={8}
        md={9}
        lg={10}
        onClick={isMessage ? handleMessageClick : handleDealClick}
        sx={{
          py: 4,
          display: 'flex',
          cursor: 'pointer',
          backgroundColor: isViewed ? '' : info.lightest,
          borderBottom: '1px solid lightgrey',
          '&:hover': {
            backgroundColor: isViewed ? 'grey.200' : info.lightest,
          },
        }}
      >
        <Stack
          sx={{ flex: 1, pr: 5 }}
          direction="row"
          alignItems="center"
          justifyContent="space-betwen"
        >
          <Box sx={{ flex: 1 }}>
            {renderContent()}
            <Typography color="text.secondary" variant="caption" sx={{ mt: 1 }}>
              sent {formatSentAt} ago
            </Typography>
          </Box>
          {!isViewed && <Badge color="primary" variant="dot" />}
        </Stack>
      </Grid>
    </>
  );
};
