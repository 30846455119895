import {
  Box,
  Card,
  Fab,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import Approval from '@mui/icons-material/Approval';
import { Link, useNavigate } from 'react-router-dom';
import { LinkedIn } from '@mui/icons-material';

export const UsersTable = ({ items = [], onApproveClick }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((investor) => {
              const handleApprove = (e) => {
                e.stopPropagation();
                onApproveClick(investor);
              };

              const onClickLinkedIn = () => {
                window.open(investor.linkedIn, '_blank', 'noopener,noreferrer');
              };

              return (
                <TableRow key={investor.id}>
                  <TableCell>
                    <Stack alignItems="center" direction="row" spacing={2}>
                      <Typography variant="subtitle2">
                        {investor.name}
                      </Typography>
                      {investor.linkedIn && (
                        <IconButton color={'info'} onClick={onClickLinkedIn}>
                          <LinkedIn />
                        </IconButton>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {investor.qualification?.category ?? 'Not Selected'}
                  </TableCell>
                  <TableCell>{investor.email}</TableCell>
                  <TableCell>{investor.phone}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Approve this Investor" placement="top">
                      <Fab
                        variant="extended"
                        size="small"
                        color={'success'}
                        onClick={handleApprove}
                        sx={{ p: 2 }}
                      >
                        <Approval sx={{ mr: 1 }} />
                        Approve
                      </Fab>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};
