import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {
  Badge,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useSessionUser } from '../hooks/session/useSessionUser';
import {
  Business,
  Logout,
  Notifications,
  Payment,
  Settings,
} from '@mui/icons-material';
import { Role, RoleLabel } from '../enums/role.enum';
import { useLocation, useNavigate } from 'react-router-dom';
import { gold } from '../theme/colors';
import { NavItems } from './nav-items';
import { useNotifications } from '../hooks/notifications/use-notifications';
import { useUser } from '../hooks/users/use-users';

export const TopNav = ({ isRegistration }) => {
  const { sessionUser } = useSessionUser();
  const { data: user } = useUser({ id: sessionUser?.id });
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const { logout } = useAuth0();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isRegistered = sessionUser?.is_registered;

  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;
  const isInvestor = sessionUser?.role === Role.Investor;

  const isApproved = user?.approvedAt;
  const pages = isApproved ? NavItems(sessionUser?.role) : [];

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { data: notifications, isLoading } = useNotifications({
    options: {
      enabled: isRegistered && !isSysAdmin,
    },
  });

  const unreadNotifications = notifications
    ? notifications.filter((n) => n.views?.length === 0).length
    : 0;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    handleCloseUserMenu();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const goToAccountSettings = (e) => {
    e.preventDefault();
    handleCloseUserMenu();
    navigate('/settings/account');
  };

  const goToOrgSettings = (e) => {
    e.preventDefault();
    handleCloseUserMenu();
    navigate('/settings/organization');
  };

  const goToSubscriptions = (e) => {
    e.preventDefault();
    handleCloseUserMenu();
    navigate('/subscriptions');
  };

  return (
    <AppBar
      position="static"
      style={{ marginTop: 0, backgroundColor: 'black' }}
    >
      <Toolbar disableGutters sx={{ px: 3, py: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ flex: 1 }}
        >
          {(!isMobile || !isRegistered) && (
            <img
              alt="Opportunity Zone Deals Logo"
              src={'/images/logos/ozd_white_multi_color_inline.png'}
              style={{
                display: isMobile && isRegistered ? 'none' : 'flex',
                height: '50px',
              }}
            />
          )}
          {isMobile && (
            <>
              {isRegistered && (
                <IconButton
                  size="large"
                  onClick={handleOpenNavMenu}
                  color={'inherit'}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.title}
                    onClick={() => navigate(page.path)}
                  >
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          {isMobile && isRegistered && (
            <img
              alt="Opportunity Zone Deals Logo"
              src={'/images/logos/ozd_white_multi_color_inline.png'}
              style={{
                height: '30px',
              }}
            />
          )}
          {!isMobile && isRegistered && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={'center'}
              spacing={2}
              sx={{ flex: 1 }}
            >
              {pages.map((page) => {
                const isApprovals = page.title === 'Approvals';
                const active = page.path
                  ? isApprovals
                    ? location.pathname.indexOf('approvals') > -1
                    : location.pathname === page.path
                  : false;

                return (
                  <Button
                    key={page.title}
                    onClick={() => navigate(page.path)}
                    sx={{
                      width: '150px',
                      color: active ? gold.main : 'white',
                      '&:hover': {
                        color: gold.main,
                      },
                    }}
                  >
                    {page.title}
                  </Button>
                );
              })}
            </Stack>
          )}
          <Stack direction="row" spacing={3}>
            {!isSysAdmin && isRegistered && isApproved && (
              <Tooltip title={'Notifications'}>
                <IconButton
                  size="large"
                  onClick={() => navigate('/notifications')}
                  sx={{
                    color:
                      location.pathname === '/notifications'
                        ? gold.main
                        : 'white',
                  }}
                >
                  <Badge badgeContent={unreadNotifications} color="error">
                    <Notifications />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar src={sessionUser?.picture} />
            </IconButton>
            <Menu
              sx={{
                mt: '45px',
                '& > *': {
                  borderRadius: 1,
                },
              }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Stack sx={{ px: 2, pb: 1 }}>
                <Typography variant={'caption'} color={'text.secondary'}>
                  {RoleLabel[sessionUser.role]}
                </Typography>
                <Typography>{sessionUser.name}</Typography>
              </Stack>
              <Divider />
              {isRegistered && (
                <MenuItem onClick={goToAccountSettings}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Account Settings</ListItemText>
                </MenuItem>
              )}
              {isRegistered && isOrgAdmin && (
                <MenuItem onClick={goToOrgSettings}>
                  <ListItemIcon>
                    <Business fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Sponsor Settings</ListItemText>
                </MenuItem>
              )}
              {isRegistered && isOrgAdmin && (
                <MenuItem onClick={goToSubscriptions}>
                  <ListItemIcon>
                    <Payment fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Billing</ListItemText>
                </MenuItem>
              )}
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>Sign out</ListItemText>
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
