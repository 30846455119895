import Photo from '@mui/icons-material/Photo';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { StorageBucket } from '../../helpers/supabase';
import { neutral } from '../theme/colors';
import { OrganizationLink } from '../organizations/organization-link';
import { DealTypeBadge } from './deal-type-badge';
import { DealPaidChip } from './chips/deal-paid-chip';

const IMG_WIDTH = 125;
const IMG_HEIGHT = 75;

export const DealInfoBlock = ({ deal, showOrg = true }) => {
  const hasImg = deal.images?.length;
  const imageSrc =
    hasImg &&
    `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.OFFERING_IMAGES}/${deal.id}/${deal.images[0].name}?crop=focalpoint&fit=crop&h=${IMG_HEIGHT}&w=${IMG_WIDTH}`;

  const activeSub = deal.subscriptions?.find((s) => !s.isCancelled);

  return (
    <Stack>
      <Stack direction={'row'} justifyContent={'flex-start'} columnGap={2}>
        <DealTypeBadge type={deal?.type}>
          {hasImg ? (
            <img
              src={imageSrc}
              width={IMG_WIDTH}
              height={IMG_HEIGHT}
              alt={deal.name}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: neutral[200],
                borderRadius: 5,
              }}
            >
              <Photo
                sx={{
                  width: IMG_WIDTH,
                  height: IMG_HEIGHT,
                }}
              />
            </div>
          )}
        </DealTypeBadge>
        <Stack>
          <Typography variant="subtitle2">
            <b>{deal.name}</b>
          </Typography>
          {deal.city && deal.state && (
            <Typography variant="subtitle2">
              {deal.city}, {deal.state}
            </Typography>
          )}
          {showOrg && (
            <OrganizationLink deal={deal} organization={deal.organization} />
          )}
        </Stack>
        {activeSub && (
          <Stack sx={{ flex: 1 }} direction={'row'} justifyContent={'flex-end'}>
            <DealPaidChip />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
