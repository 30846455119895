import React from 'react';
import { Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useApprovalDeals } from '../../components/hooks/deals/use-deals';
import { useApprovalOrganizations } from '../../components/hooks/organizations/use-organizations';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../components/hooks/util/use-snackbar';
import OrganizationApprovals from '../../components/organizations/organization-approvals';
import DealApprovals from '../../components/deals/deal-approvals';
import {
  useApprovalUsers,
  useRejectedUsers,
  useRejectUser,
} from '../../components/hooks/users/use-users';
import UserApprovals from '../../components/users/user-approvals';

const ApprovalType = {
  Deals: 'deals',
  Organizations: 'organizations',
  PendingInvestors: 'pending',
  RejectedInvestors: 'rejected',
};

export default function Approvals() {
  const navigate = useNavigate();
  const location = useLocation();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type') || ApprovalType.Deals;

  const { data: deals, isLoading: isLoadingDeals } = useApprovalDeals();
  const { data: organizations, isLoading: isLoadingOrgs } =
    useApprovalOrganizations();
  const { data: pendingApprovals, isLoading: isLoadingPending } =
    useApprovalUsers();
  const { data: rejectedUsers, isLoading: isLoadingRejected } =
    useRejectedUsers();

  const isLoading =
    (type === ApprovalType.Deals && isLoadingDeals) ||
    (type === ApprovalType.Organizations && isLoadingOrgs) ||
    (type === ApprovalType.PendingInvestors && isLoadingPending) ||
    (type === ApprovalType.RejectedInvestors && isLoadingRejected);

  const handleTabChange = (event, newType) => {
    const toUrl = '?type=' + newType;
    navigate(toUrl);
  };

  return (
    <Container maxWidth="xl">
      {renderSnackbar()}
      <Typography variant="h4">Approvals</Typography>
      <Stack spacing={3} sx={{ mt: 1 }}>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Tabs value={type} onChange={handleTabChange}>
              <Tab
                label={`Deals${deals ? ` (${deals?.length})` : ''}`}
                value={ApprovalType.Deals}
              />
              <Tab
                label={`Organizations${
                  organizations ? ` (${organizations?.length})` : ''
                }`}
                value={ApprovalType.Organizations}
              />
              <Tab
                label={`Pending Investors${
                  pendingApprovals ? ` (${pendingApprovals?.length})` : ''
                }`}
                value={ApprovalType.PendingInvestors}
              />
              <Tab
                label={`Rejected Investors${
                  rejectedUsers ? ` (${rejectedUsers?.length})` : ''
                }`}
                value={ApprovalType.RejectedInvestors}
              />
            </Tabs>
            {type === ApprovalType.Deals ? (
              <DealApprovals
                deals={deals}
                isLoading={isLoading}
                setSnackbar={setSnackbar}
              />
            ) : type === ApprovalType.Organizations ? (
              <OrganizationApprovals
                organizations={organizations}
                isLoading={isLoading}
                setSnackbar={setSnackbar}
              />
            ) : type === ApprovalType.PendingInvestors ? (
              <UserApprovals
                users={pendingApprovals}
                isLoading={isLoading}
                setSnackbar={setSnackbar}
              />
            ) : type === ApprovalType.RejectedInvestors ? (
              <UserApprovals
                users={rejectedUsers}
                isLoading={isLoading}
                setSnackbar={setSnackbar}
                isReject
              />
            ) : (
              <></>
            )}
          </>
        )}
      </Stack>
    </Container>
  );
}
