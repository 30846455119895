import { Alert, Stack } from '@mui/material';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { UsersTable } from './users-table';
import { useApproveUser, useRejectUser } from '../hooks/users/use-users';

export default function UserApprovals({
  users,
  isLoading,
  isReject,
  setSnackbar,
}) {
  const approveUser = useApproveUser();
  const rejectUser = useRejectUser();

  const handleApprove = async (user) => {
    try {
      approveUser.mutate({
        id: user.id,
      });
      setSnackbar({ message: user.name + ' has been approved.' });
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
    }
  };

  const handleReject = async (user) => {
    try {
      rejectUser.mutate(user);
      setSnackbar({ message: user.name + ' has been rejected.' });
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
    }
  };

  return isLoading ? (
    <Stack alignItems="center">
      <CircularProgress />
    </Stack>
  ) : (
    <>
      {users?.length > 0 ? (
        <UsersTable
          items={users}
          onApproveClick={handleApprove}
          onRejectClick={!isReject ? handleReject : undefined}
        />
      ) : (
        <Alert severity="success" sx={{ width: '100%' }}>
          {`You're all caught up! There are no Investors to approve at this time. 😎`}
        </Alert>
      )}
    </>
  );
}
